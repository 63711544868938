<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NewsCard6") }}</h1>
        <div id="news-contain">
          <img
            height="200"
            width="230"
            :src="getImageUrlbyName('NewsBig6')"
            href=""
            id="pic"
            align="left"
          />
          <div id="innews">
            <div id="inf-news-carddate">{{ $t("message.CNDate6") }}</div>
          </div>
          <span id="pad" v-html="$t('message.News6Text1')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text2')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text3')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text4')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text5')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text6')"></span>
          <span id="pad" v-html="$t('message.News6Text7')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text8')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text9')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text10')"></span>
          
          <span id="pad" v-html="$t('message.News6Text11')"></span>
          <p></p>
          <span id="pad" v-html="$t('message.News6Text12')"></span>
          <v-card id="buy-card">
          <v-btn small id="buy-btn">{{ $t("message.BuyService") }} </v-btn>
          <div id="history-card-text">
            {{ $t("message.MakeRequest") }}
          </div>
        </v-card>
          <div id="innews">
          <a id="backto" @click="$router.push('/informs/news')">
            <v-icon large>mdi-arrow-left-bold-circle</v-icon>
            <a id = "pad-left">{{ $t("message.BackToList") }}</a>          
          </a>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import InformsDrawer from "../../../../views/Navigations/Informs/InformsDrawer";
export default {
  components: {
    InformsDrawer,
  },
};
</script>
<style>

</style>